import PolicyContainer from './PolicyContainer';
import useTermly from './useTermly';

function Privacy () {
  useTermly();
  return (
    <PolicyContainer>
      <div name="termly-embed" data-id="0d951a45-3e72-40bc-9fbc-09c1a0553ae3" data-type="iframe" />
    </PolicyContainer>
  );
}

export default Privacy;
