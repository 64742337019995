import styled from 'styled-components';
import { memo, useContext } from 'react';

import { NavigationContext } from '../../context/NavigationContext';

import colors from '../../styles/colors';
import grids from '../../styles/grids';

import { JumbotronText, H3, H2 } from '../ui/Text';
import EmailForm from '../form/EmailForm';
import breakpoints from '../../styles/breakpoints';

const TheEndWrapper = styled.section`
  height: auto;
  display: grid;
  grid-column-start: overflow-left;
  grid-column-end: overflow-right;
  ${grids.twelveColumnOneRowOverflow}
`;

const Background = styled.div`
  grid-area: 1 / overflow-left / 2 / span 14;
  background: ${colors.grey.five};
`;

const TheEndContent = styled.div`
  grid-area: 1 / one / 1 / twelve;
  display: grid;
  grid-template-columns: [left-edge] repeat(12, 1fr) [right-edge];
  grid-template-rows: 15em 20em 6em 1fr;
  grid-template-areas: "one-one one-two one-three one-four one-five one-six one-seven one-eight one-nine one-ten one-eleven one-twelve"
                       "two-one two-two two-three two-four two-five two-six two-seven two-eight two-nine two-ten two-eleven two-twelve"
                       "three-one three-two three-three three-four three-five three-six three-seven three-eight three-nine three-ten three-eleven three-twelve"
                       "four-one four-two four-three four-four four-five four-six four-seven four-eight four-nine four-ten four-eleven four-twelve";
  column-gap: 1em;
  row-gap: 0;

  @media (${breakpoints.xSmall}) {
    grid-template-rows: 11em 30em 6em 1fr;
  }
`;

const HeadingLineWrapper = styled.div`
  grid-area: two-one / two-one / two-one / two-twelve;
  justify-self: center;
  align-self: top;
  margin: 5em 0 0 0;
  width: 12em;
  display: grid;
  align-items: center;
`;

function TheEnd () {
  const navigationContext = useContext(NavigationContext);
  const { screenWidth } = navigationContext;

  return (
    <TheEndWrapper>
      <Background />
      <TheEndContent>
        {screenWidth > 800
          ? (
            <H3
              $gridArea="one-four / one-four / one-four / one-six"
              $alignSelf="end"
              $color={colors.primary.main}
            >
              The End
            </H3>
          )
          : null}

        <JumbotronText
          $gridArea="two-one / two-one / two-one / two-twelve"
          $margin="0 0 1em 0"
          $alignSelf="top"
          $justifySelf="center"
          $color={colors.grey.two}
          $textAlign={screenWidth < breakpoints.xSmallNumber ? 'center' : 'right'}
        >
          Thanks for
          {screenWidth < breakpoints.xSmallNumber ? <br /> : ' '}
          Swinging By.
        </JumbotronText>
        <HeadingLineWrapper>
          <svg viewBox="0 0">
            <line x="0" y1="0" x2="12em" y2="0" stroke={colors.grey.three} strokeWidth="1.2em" />
          </svg>
        </HeadingLineWrapper>
        <H2
          $gridArea="three-four / three-four / three-four / three-nine"
          $color={colors.grey.one}
          $alignSelf="center"
        >
          Don’t be a stranger.
        </H2>
        <EmailForm
          $gridArea={screenWidth < breakpoints.smallNumber ? 'four-two / four-two / four-two / four-eleven' : 'four-four / four-four / four-four / four-nine'}
          $margin="3em 0 8em 0"
          textAreaPrompt="Message"
        />
      </TheEndContent>
    </TheEndWrapper>
  );
}

export default memo(TheEnd);
