import { memo } from 'react';
import styled from 'styled-components';
import { animated, useSpring, config } from 'react-spring';

import colors from '../../styles/colors';

const BurgerIconWrapper = styled.div`
  grid-area: ${(props) => props.$gridArea || '1 / 1 / 1 / 1'};
  margin: ${({ $margin }) => $margin || '0 0 0 0'};
  height: ${(props) => props.$height || '10em'};
  z-index: 1;
`;

function BurgerIcon ({ $gridArea, $margin, isX, $height, onClick, isGrey }) {
  const transitionStyles = useSpring({
    to: {
      y1_1: isX ? 1 : 3,
      y1_2: isX ? 9 : 6,
      y2_1: isX ? 9 : 3,
      y2_2: isX ? 1 : 6,
    },
    config: config.slow,

  });

  return (
    <BurgerIconWrapper
      $gridArea={$gridArea}
      $margin={$margin}
      $height={$height}
      onClick={onClick}
    >
      <svg viewBox="-1 0 11 11" width={$height} height={$height}>
        <animated.line x="1" y1={transitionStyles.y1_1} x2="9" y2={transitionStyles.y2_1} stroke={isGrey ? colors.grey.one : colors.grey.five} strokeWidth="1" />
        <animated.line x="1" y1={transitionStyles.y1_2} x2="9" y2={transitionStyles.y2_2} stroke={isGrey ? colors.grey.one : colors.grey.five} strokeWidth="1" />
      </svg>
    </BurgerIconWrapper>
  );
}

export default memo(BurgerIcon);
