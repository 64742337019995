/*
EXTRA-CONDENSED BOLD
font-family: acumin-pro-extra-condensed,sans-serif;
font-weight: 700;
font-style: normal;

EXTRA-CONDENSED BOLD ITALIC
font-family: acumin-pro-extra-condensed,sans-serif;
font-weight: 700;
font-style: italic;

EXTRA-CONDENSED REGULAR
font-family: acumin-pro-extra-condensed,sans-serif;
font-weight: 400;
font-style: normal;

EXTRA-CONDENSED ITALIC
font-family: acumin-pro-extra-condensed,sans-serif;
font-weight: 400;
font-style: italic;

EXTRA-CONDENSED MEDIUM
font-family: acumin-pro-extra-condensed,sans-serif;
font-weight: 500;
font-style: normal;

EXTRA-CONDENSED MEDIUM-ITALIC
font-family: acumin-pro-extra-condensed,sans-serif;
font-weight: 500;
font-style: italic;

*/

const fonts = {
  condensed: {
    fontFamily: 'acumin-pro-extra-condensed, open-sans, helvetica, sans-serif',
    bold: {
      weight: 700,
      style: 'normal',
    },
    boldItalic: {
      weight: 700,
      style: 'italic',
    },
    regular: {
      weight: 400,
      style: 'normal',
    },
    italic: {
      weight: 400,
      style: 'italic',
    },
    medium: {
      weight: 500,
      style: 'normal',
    },
    mediumItalic: {
      weight: 500,
      style: 'italic',
    },
  },
};

export default fonts;
