import { memo, useContext } from 'react';
import styled from 'styled-components';

import { NavigationContext } from '../../context/NavigationContext';

import colors from '../../styles/colors';
import grids from '../../styles/grids';

import { JumbotronText } from '../ui/Text';
import ThreeSmallCards from '../cards/ThreeSmallCards';
import breakpoints from '../../styles/breakpoints';

const ServicesWrapper = styled.section`
  height: auto;
  display: grid;
  grid-column-start: overflow-left;
  grid-column-end: overflow-right;
  ${grids.twelveColumnOneRowOverflow};
`;

const ServicesContent = styled.div`
  grid-area: 1 / one / 1 / twelve;
  display: grid;
  grid-template-columns: [left-edge] repeat(12, 1fr) [right-edge];
  grid-template-rows: 15em 8em 1fr 10em;
  grid-template-areas: "header-one header-two header-three header-four header-five header-six header-seven header-eight header-nine header-ten header-eleven header-twelve"
                        "one-one one-two one-three one-four one-five one-six one-seven one-eight one-nine one-ten one-eleven one-twelve"
                        "two-one two-two two-three two-four two-five two-six two-seven two-eight two-nine two-ten two-eleven two-twelve"
                        "three-one three-two three-three three-four three-five three-six three-seven three-eight three-nine three-ten three-eleven three-twelve";
  column-gap: 1em;
  row-gap: 0;
`;

const HeadingLineWrapper = styled.div`
  grid-area: one-one / one-one / one-one / one-twelve;
  justify-self: center;
  align-self: top;
  margin: 1em 0 0 0;
  width: 12em;
  display: grid;
  align-items: start;
`;

const Background = styled.div`
  grid-area: 1 / overflow-left / 2 / span 14;
  background: ${colors.grey.one};
`;

const OrangePillWrapper = styled.div`
  grid-area: overflow-left / overflow-left / overflow-left / three;
  overflow: hidden;
`;

const OrangePill = styled.div`
  background: ${colors.primary.accentLight};
  height: 70em;
  border-radius: 40em;
  z-index: 90;
  margin: 10em 0 0 10em;
  max-width: 40em;
`;

function Services () {
  const navigationContext = useContext(NavigationContext);
  const { screenWidth, servicesRef } = navigationContext;

  return (
    <ServicesWrapper ref={servicesRef}>
      <Background />
      <OrangePillWrapper>
        <OrangePill />
      </OrangePillWrapper>
      <ServicesContent>
        <JumbotronText
          $gridArea="header-one / header-one / header-one / header-twelve"
          $margin="0 0 0 0"
          $alignSelf="end"
          $justifySelf="center"
          $color={colors.grey.five}
          $textAlign="center"
        >
          Services
        </JumbotronText>
        <HeadingLineWrapper>
          <svg viewBox="0 0">
            <line x="0" y1="0" x2="12em" y2="0" stroke={colors.grey.three} strokeWidth="1.2em" />
          </svg>
        </HeadingLineWrapper>
        <ThreeSmallCards
          $gridArea={screenWidth < 700 ? 'two-one / two-one / two-one / two-twelve' : 'two-two / two-two / two-two / two-eleven'}
          title={screenWidth < breakpoints.smallNumber ? 'Let us build you something.' : 'Can\'t find exactly what you need? Let us build you something.'}
          zIndex="110"
          cardOneCircleText="Devices"
          cardOneText="We can build many kinds of interfaces and devices for specific applications."
          cardTwoCircleText="3D Printing"
          cardTwoText="We have experience developing and deploying small to medium websites and cloud systems. Need help with new cloud based SasS platforms? We can do that too. Heck, we even built this website from scratch."
          cardThreeCircleText="eCommerce"
          cardThreeText="sndwrks can implement custom eCommerce solutions from fulfillment to internal tools. We are most familiar with ShipStation, but if your tool has an API there's a good chance we can use it. Get in touch!"
        />
      </ServicesContent>
    </ServicesWrapper>
  );
}

export default memo(Services);
