import { memo, useContext } from 'react';
import styled from 'styled-components';

import { NavigationContext } from '../../context/NavigationContext';

import breakpoints from '../../styles/breakpoints';
import colors from '../../styles/colors';

import { H4, P } from '../ui/Text';

const SmallCardWrapper = styled.div`
  grid-area: ${(props) => props.$gridArea || '1 / 1 / 1 / 1'};
  display: grid;
  grid-template-rows: 1fr 3em 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "circle"
                       "line"
                       "text";
  height: ${(props) => props.$height || 'auto'};
  text-align: left;
  margin: ${({ $margin }) => $margin || '0 0 0 0'};
`;

const SmallCardCircle = styled.div`
  grid-area: circle;
  height: 10em;
  width: 10em;
  border-radius: 50%;
  align-self: end;
  justify-self: center;
  display: grid;
  margin: 0 0 .4em 0;
  background-color: ${colors.primary.accentLight};

  @media(${breakpoints.xSmall}) {
    height: 9em;
    width: 9em;
  }
`;

const SmallCardLine = styled.svg`
  grid-area: line;
  justify-self: center;
  align-self: center;
`;

function SmallCard ({ circleText, $gridArea, $height, $margin, text }) {
  const navigationContext = useContext(NavigationContext);
  const { screenWidth } = navigationContext;

  return (
    <SmallCardWrapper
      $gridArea={$gridArea}
      $height={$height}
      $margin={$margin}
    >
      <SmallCardCircle>
        <H4 $justifySelf="center" $margin="0 0 .25em 0" $color={colors.grey.five}>{circleText}</H4>
      </SmallCardCircle>
      <SmallCardLine viewBox="0 0" height=".3em" width="10em">
        <line x="0" y1="0" x2="10em" y2="0" stroke={colors.grey.four} strokeWidth=".3em" />
      </SmallCardLine>
      <P
        $gridArea="text"
        $maxWidth={screenWidth < breakpoints.xSmallNumber ? '10em' : '14em'}
        $color={colors.grey.one}
        $textAlign="center"
        $lineHeight="1.7"
        $justifySelf="center"
        $alignSelf="top"
      >
        {text}
      </P>
    </SmallCardWrapper>
  );
}

export default memo(SmallCard);
