import styled from 'styled-components';
import { useContext } from 'react';

import { NavigationContext } from '../../context/NavigationContext';

import colors from '../../styles/colors';
import breakpoints from '../../styles/breakpoints';
import grids from '../../styles/grids';

import { H2, JumbotronText } from '../ui/Text';

const Wrapper = styled.section`
  display: grid;
  background: ${colors.grey.five};
  grid-column-start: overflow-left;
  grid-column-end: overflow-right;
  ${grids.twelveColumnOneRowOverflow};
`;

const ContentWrapper = styled.div`
  grid-area: 1 / one / 1 / twelve;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30rem 30rem;
  margin: 0 30rem 0 0;
  column-gap: 1rem;
  row-gap: 0;

  @media (${breakpoints.medium}) {
    margin: 0 20rem 0 0;
  }

  @media (${breakpoints.xSmall}) {
    margin: 0 0 0 0;
  }
`;

function Osc () {
  const { oscRef } = useContext(NavigationContext);
  return (
    <Wrapper ref={oscRef}>
      <ContentWrapper>
        <JumbotronText
          $color={colors.primary.main}
          $alignSelf="end"
        >
          OSC
        </JumbotronText>
        <H2
          $color={colors.grey.one}
          $alignSelf="top"
        >
          More details coming soon!
        </H2>
      </ContentWrapper>
    </Wrapper>
  );
}

export default Osc;
