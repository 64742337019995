import PolicyContainer from './PolicyContainer';
import useTermly from './useTermly';

function Disclaimer () {
  useTermly();
  return (
    <PolicyContainer>
      <div name="termly-embed" data-id="ff8534ac-ea4d-4cff-8560-6652874cdd2a" data-type="iframe" />
    </PolicyContainer>
  );
}

export default Disclaimer;
