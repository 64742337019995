import PolicyContainer from './PolicyContainer';
import useTermly from './useTermly';

function Returns () {
  useTermly();
  return (
    <PolicyContainer>
      <div name="termly-embed" data-id="1ecf6eef-201e-431e-a18c-8ed2a415e80f" data-type="iframe" />
    </PolicyContainer>
  );
}

export default Returns;
