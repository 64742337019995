import styled from 'styled-components';

import colors from '../../styles/colors';

const VWrapper = styled.div`
  grid-area: ${(props) => props.$gridArea || '1 / 1 / 1 / 1'};
  justify-self: ${(props) => props.justifySelf || 'center'};
  align-self: ${(props) => props.alignSelf || 'center'};
  width: 5em;
  margin: ${({ $margin }) => $margin || '0 0 0 0'};
`;

// eslint-disable-next-line import/prefer-default-export
export function V (props) {
  const { $gridArea, justifySelf, alignSelf, margin } = props;

  return (
    <VWrapper
      $gridArea={$gridArea}
      $justifySelf={justifySelf}
      $alignSelf={alignSelf}
      $margin={margin}
    >
      <svg xmlns="http://www.w3.org/svg" viewBox="0 0 50 25">
        <line
          x1="0"
          y1="0"
          x2="25"
          y2="20"
          stroke={colors.grey.three}
          strokeWidth=".4"
          strokeLinecap="round"
        />
        <line
          x1="25"
          y1="20"
          x2="50"
          y2="0"
          stroke={colors.grey.three}
          strokeWidth=".4"
          strokeLinecap="round"
        />
      </svg>
    </VWrapper>
  );
}
