const breakpoints = {
  medium: 'max-width: 1200px',
  mediumNumber: 1200,
  small: 'max-width: 1000px',
  smallNumber: 1000,
  xSmall: 'max-width: 620px',
  xSmallNumber: 620,
};

export default breakpoints;
