import { useContext, useState, memo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { NavigationContext } from '../../context/NavigationContext';

import colors from '../../styles/colors';
import grids from '../../styles/grids';
import breakpoints from '../../styles/breakpoints';

import { SndwrksLogo } from '../ui/SndwrksLogo';
import ButtonNav from './ButtonNav';
import BurgerIcon from '../ui/BurgerIcon';
import BurgerNav from './BurgerNav';

const NavWrapper = styled.nav`
  grid-area: nav-overflow-left / nav-overflow-left / nav-overflow-right / nav-overflow-right;
  z-index: 1000;
  display: grid;
  ${grids.twelveColumnOneRowOverflow}
`;

const Background = styled.div`
  grid-area: 1 / overflow-left / 1 / overflow-right;
  box-shadow: ${(props) => (props.boxShadow ? '4px 4px 12px 0px rgba(0,0,0,0.3)' : 'none')};
  background-color: ${({ $color }) => $color};
  transition: background-color .5s ease-in-out, box-shadow .5s ease-in-out;
  z-index: 1;
  border-radius: 0 0 2em 2em;
  margin: 0 2em 0 2em;

  @media (${breakpoints.small}) {
    border-radius: 0;
    margin: 0;
  }
`;

const LogoLink = styled(Link)`
  grid-area: one / one / one / three;
  z-index: 1;
`;

function Nav () {
  const navigationContext = useContext(NavigationContext);
  const { showNav, navigateToHome, screenWidth } = navigationContext;

  const [isBurgerNavShowing, setIsBurgerNavShowing] = useState(false);

  function handleBurgerClick () {
    setIsBurgerNavShowing(!isBurgerNavShowing);
  }

  return (
    <NavWrapper>
      <Background
        $color={showNav ? colors.primary.main : 'none'}
        boxShadow={showNav}
      />
      <LogoLink to="/" onClick={navigateToHome}>
        <SndwrksLogo
          $alignSelf="center"
          $justifySelf="start"
          // eslint-disable-next-line no-nested-ternary
          $margin={screenWidth < 1000 ? '1em 0 .6em 0' : screenWidth < 1470 ? '1em 0 .6em 2.5em' : '1em 0 .6em 0'}
          $gridArea={screenWidth < breakpoints.smallNumber ? 'one / one / one / four' : 'one / one / one / three'}
          $opacity={showNav ? '1' : '0'}
          $height="3em"
          $width={screenWidth < breakpoints.smallNumber ? '10em' : '12em'}
        />
      </LogoLink>
      {screenWidth + 200 < breakpoints.smallNumber
        ? (
          <>
            <BurgerIcon
              $gridArea="twelve / twelve / twelve / twelve"
              $margin="1.3em 0 0 0"
              $height="2em"
              onClick={handleBurgerClick}
              isX={isBurgerNavShowing}
              isGrey={!showNav && !isBurgerNavShowing}
            />
            <BurgerNav
              isShowing={isBurgerNavShowing}
              setIsShowing={setIsBurgerNavShowing}
            />
          </>

        )
        : <ButtonNav />}
    </NavWrapper>
  );
}

export default memo(Nav);
