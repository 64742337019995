import styled, { createGlobalStyle } from 'styled-components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { NavigationContextProvider } from '../../context/NavigationContext';

import Nav from '../nav/Nav';
import ScrollContainer from '../scroll-container/ScrollContainer';
import Privacy from '../policies/Privacy';
import Terms from '../policies/Terms';
import Returns from '../policies/Returns';
import Shipping from '../policies/Shipping';
import Disclaimer from '../policies/Disclaimer';

import fonts from '../../styles/fonts';
import colors from '../../styles/colors';
import grids from '../../styles/grids';

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    margin: 0;
    background: ${colors.primary.main};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: ${fonts.condensed.fontFamily};
    font-weight: ${fonts.condensed.bold.weight};
    font-style: ${fonts.condensed.bold.style};
    font-size: 16px;
    background: ${colors.primary.main};
  }
  #root {
    height: 100%;
  }
`;

const AppWrapper = styled.div`
  height: 100%;
  display: grid;
  ${grids.twelveColumnTwoRowOverflow}
`;

const ScrollWrapper = styled.div`
  display: grid;
  height: 100%;
  grid-area: nav-overflow-left / nav-overflow-left / overflow-right / nav-overflow-right;
  ${grids.twelveColumnOneRowOverflow}
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function App () {
  return (
    <BrowserRouter>
      <NavigationContextProvider>
        <AppWrapper>
          <GlobalStyle />
          <Nav />
          <Routes>
            <Route path="/disclaimer" element={<ScrollWrapper><Disclaimer /></ScrollWrapper>} />
            <Route path="/privacy" element={<ScrollWrapper><Privacy /></ScrollWrapper>} />
            <Route path="/terms" element={<ScrollWrapper><Terms /></ScrollWrapper>} />
            <Route path="/returns" element={<ScrollWrapper><Returns /></ScrollWrapper>} />
            <Route path="/shipping" element={<ScrollWrapper><Shipping /></ScrollWrapper>} />
            <Route path="*" element={<ScrollContainer />} />
          </Routes>
        </AppWrapper>
      </NavigationContextProvider>
    </BrowserRouter>
  );
}

export default App;
