const grids = {
  twelveColumnTwoRowOverflow: `
    grid-template-columns: [left-edge] auto [left-body] repeat(12, minmax(auto, 100px)) [right-body] auto [right-edge];
    grid-template-rows: 4em 1fr;
    grid-template-areas: "nav-overflow-left nav-one nav-two nav-three nav-four nav-five nav-six nav-seven nav-eight nav-nine nav-ten nav-eleven nav-twelve nav-overflow-right"
                         "overflow-left body body body body body body body body body body body body overflow-right";
    column-gap: 1em;
    row-gap: 0;`,
  twelveColumnOneRowOverflow: `
    grid-template-columns: [left-edge] auto [left-body] repeat(12, minmax(auto, 100px)) [right-body] auto [right-edge];
    grid-template-rows: 1fr;
    grid-template-areas: "overflow-left one two three four five six seven eight nine ten eleven twelve overflow-right";
    column-gap: 1em;
    row-gap: 0;`,
  twelveColumnOneRow: `
    grid-template-columns: [left-edge] repeat(12, minmax(auto, 100px)) [right-edge];
    grid-template-rows: 1fr;
    grid-template-areas: "one two three four five six seven eight nine ten eleven twelve";
    column-gap: 1em;
    row-gap: 0;`,
  twelveColumnThreeRow: `
    grid-template-columns: [left-edge] repeat(12, 1fr) [right-edge];
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: "one-one one-two one-three one-four one-five one-six one-seven one-eight one-nine one-ten one-eleven one-twelve"
                         "two-one two-two two-three two-four two-five two-six two-seven two-eight two-nine two-ten two-eleven two-twelve";
    column-gap: 1em;
    row-gap: 0;`,
  twelveColumnHeaderThreeRow: `
    grid-template-columns: [left-edge] repeat(12, 1fr) [right-edge];
    grid-template-rows: 15em 1fr 1fr 1fr;
    grid-template-areas: "header-one header-two header-three header-four header-five header-six header-seven header-eight header-nine header-ten header-eleven header-twelve"
                         "one-one one-two one-three one-four one-five one-six one-seven one-eight one-nine one-ten one-eleven one-twelve"
                         "two-one two-two two-three two-four two-five two-six two-seven two-eight two-nine two-ten two-eleven two-twelve"
                         "three-one three-two three-three three-four three-five three-six three-seven three-eight three-nine three-ten three-eleven three-twelve";
    column-gap: 1em;
    row-gap: 0;`,
};

export default grids;
