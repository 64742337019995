import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { NavigationContext } from '../../context/NavigationContext';

import SmallCard from './SmallCard';
import { H2 } from '../ui/Text';
import colors from '../../styles/colors';
import breakpoints from '../../styles/breakpoints';

const ThreeSmallCardWrapper = styled.div`
  grid-area: ${(props) => props.$gridArea || '1 / 1 / 1 / 1'};
  display: grid;
  grid-template-rows: [top] 10em [middle] 36em [bottom];
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "heading heading heading"
                       "card-one card-two card-three";
  column-gap: 1em;
  text-align: left;
  margin: ${({ $margin }) => $margin || '0 0 0 0'};
  z-index: ${(props) => props.zIndex};

  @media (${breakpoints.small}) {
    grid-template-rows: [top] 10em [middle] repeat(3, min-content) [bottom];
    grid-template-columns: 1fr;
    grid-template-areas: "heading"
                        "card-one"
                        "card-two"
                        "card-three";
  }


  @media(${breakpoints.xSmall}) {
    grid-template-columns: 90vw;
  }
`;

const ThreeSmallCardBackground = styled.div`
  grid-area: card-one / card-one / card-one / card-three;
  background-color: ${colors.grey.five};
  border-radius: 10em;

  @media (${breakpoints.small}) {
    grid-area: card-one / card-one / card-three / card-three;
  }

  @media (${breakpoints.xSmall}) {
    border-radius: 5em;
  }
`;

const ThreeSmallCardLineWrapper = styled.div`
  grid-area: heading;
  justify-self: left;
  align-self: end;
  height: .5em;
  margin: 0 0 -.2em 15em;

  @media (${breakpoints.small}) {
    margin: 0 0 -.2em 10em;
  }

  @media (${breakpoints.xSmall}) {
    margin: 0 0 -.2em 6em;
  }
`;

function ThreeSmallCards ({
  margin,
  $gridArea,
  zIndex,
  title,
  cardOneCircleText,
  cardOneText,
  cardTwoCircleText,
  cardTwoText,
  cardThreeCircleText,
  cardThreeText,
}) {
  const [headingMargin, setHeadingMargin] = useState('0 0 .5em 6em');

  const navigationContext = useContext(NavigationContext);
  const { screenWidth } = navigationContext;

  useEffect(() => {
    function getHeadingMargin () {
      if (screenWidth < breakpoints.xSmallNumber) return '0 0 .5em 2em';
      if (screenWidth < breakpoints.smallNumber) return '0 0 .5em 4em';
      return '0 0 .5em 6em';
    }

    setHeadingMargin(getHeadingMargin());
  }, [screenWidth]);

  return (
    <ThreeSmallCardWrapper
      $margin={margin}
      $gridArea={$gridArea}
      zIndex={zIndex}
    >
      <H2
        $color={colors.grey.five}
        $alignSelf="end"
        $margin={headingMargin}
        $gridArea="heading"
        $justifySelf="left"
      >
        {title}
      </H2>
      <ThreeSmallCardBackground />
      <ThreeSmallCardLineWrapper>
        <svg viewBox="0 0">
          <line x="0" y1="0" x2={screenWidth < breakpoints.xSmallNumber ? '8em' : '12em'} y2="0" stroke={colors.grey.three} strokeWidth="1.2em" />
        </svg>
      </ThreeSmallCardLineWrapper>
      <SmallCard
        $gridArea="card-one"
        $margin={screenWidth < breakpoints.smallNumber ? '2em 0 0 0' : '2em 0 0 4em'}
        circleText={cardOneCircleText}
        text={cardOneText}
      />
      <SmallCard
        $gridArea="card-two"
        $margin={screenWidth < breakpoints.smallNumber ? '2rem 0 0 0' : '2em 0 0 0'}
        circleText={cardTwoCircleText}
        text={cardTwoText}
      />
      <SmallCard
        $gridArea="card-three"
        $margin={screenWidth < breakpoints.smallNumber ? '2rem 0 3em 0' : '2em 4em 0 0'}
        circleText={cardThreeCircleText}
        text={cardThreeText}
      />
    </ThreeSmallCardWrapper>
  );
}

export default ThreeSmallCards;
