import styled from 'styled-components';

import breakpoints from '../../styles/breakpoints';

import { ReactComponent as Logo } from '../../svg/haze-watch-light.svg';

const LogoWrapper = styled.div`
  grid-area: ${(props) => props.$gridArea || '1 / 1 / 1 / 1'};
  justify-self: ${(props) => props.$justifySelf || 'center'};
  align-self: ${(props) => props.$alignSelf || 'center'};
  margin: ${({ $margin }) => $margin || '0 0 0 0'};
  opacity: ${(props) => props.$opacity || 1};
  transition: opacity .5s ease-in-out;
  height: 10em;
  width: 25em;

  @media (${breakpoints.medium}) {
    width: 20em;
  }

  @media (${breakpoints.small}) {
    width: 18em;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export function HazeWatchLogo (props) {
  const { $gridArea, $justifySelf, $alignSelf, $margin, $opacity } = props;

  return (
    <LogoWrapper
      $gridArea={$gridArea}
      $justifySelf={$justifySelf}
      $alignSelf={$alignSelf}
      $margin={$margin}
      $opacity={$opacity}
    >
      <Logo />
    </LogoWrapper>
  );
}
