import styled from 'styled-components';

import colors from '../../styles/colors';
import breakpoints from '../../styles/breakpoints';

import Footer from '../footer/Footer';

const PolicyWrapper = styled.div`
  grid-area: one / one / one / twelve;
  display: grid;
  margin: 10em 0 0 0;
  justify-self: center;
  min-width: 50em;
  padding: 0 2em 0 2em;
  border: .3em solid ${colors.grey.five};
  border-radius: 2em;
  background-color: ${colors.grey.one};

  @media (${breakpoints.small}) {
    min-width: 25em;
  }

  @media (${breakpoints.xSmall}) {
    min-width: 0;
  }
`;

function PolicyContainer ({ children }) {
  return (
    <>
      <PolicyWrapper>{children}</PolicyWrapper>
      <Footer />
    </>
  );
}

export default PolicyContainer;
