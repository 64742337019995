import { memo, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { animated, useTransition, config, useSpringRef, useChain } from 'react-spring';
import { Link } from 'react-router-dom';

import { NavigationContext } from '../../context/NavigationContext';

import colors from '../../styles/colors';

import { H3 } from '../ui/Text';

const BurgerNavWrapper = styled(animated.div)`
  height: 100vh;
  width: 100vw;
  background-color: ${colors.primary.main};
  position: fixed;
  will-change: opacity transform;
  display: grid;
  grid-auto-rows: 8em;
  grid-template-columns: 1fr;
  margin: 0 0 0 0;
  padding: 10em 0 0 0;
`;

const StyledLink = styled(Link)`
  display: grid;
  text-decoration: none;
  border-top: .3em solid rgba(0, 0, 0, 0);
  border-bottom: .3em solid rgba(0, 0, 0, 0);
  height: 4em;
  width: 15em;
  justify-self: center;

  &:hover {
    border-top: .3em solid rgba(0, 0, 0, 0);
    border-bottom: .3em solid ${colors.grey.five70};
  }

  &:active {
    background-color: ${colors.grey.five70};
  }

  &:focus {
    -webkit-tap-highlight-color: transparent;
    border-bottom: .3em solid ${colors.grey.five70};
  }
`;

const AnimatedDiv = styled(animated.div)`
  display: grid;
`;

function BurgerNav ({ isShowing, setIsShowing }) {
  const navigationContext = useContext(NavigationContext);
  const { links } = navigationContext;

  // this may not do shit. bandaidy
  const localLinks = useMemo(() => links, [links]);

  const transitionsApi = useSpringRef();
  const transitions = useTransition(isShowing, {
    ref: transitionsApi,
    from: { transform: `translate3d(0, -100%, 0)` },
    enter: { transform: `translate3d(0, 0%, 0)` },
    leave: { transform: `translate3d(0, -105%, 0)` },
    reverse: true,
    config: {
      precision: 0.01,
      friction: 35,
    },
  });

  const linksApi = useSpringRef();
  const animationLinks = useTransition(isShowing ? Object.values(localLinks) : [], {
    ref: linksApi,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    trail: links?.length ? 200 / links.length : 200 / 4,
    config: config.default,
    delay: 100,
  });

  useChain(isShowing ? [transitionsApi, linksApi] : [linksApi, transitionsApi], [0, 0.7]);

  return transitions((styles, item) => item && (
    <BurgerNavWrapper style={styles}>
      {animationLinks((style, i) => (
        <AnimatedDiv
          style={style}
          key={i.slug}
        >
          <StyledLink
            to={i.slug}
            onClick={() => {
              setIsShowing(false);
              i.onClick();
            }}
          >
            <H3
              $lineHeight="0"
              $margin="0 0 0 0"
              $color={colors.grey.five}
              $justifySelf="center"
            >
              {i.name}
            </H3>
          </StyledLink>
        </AnimatedDiv>
      ))}
    </BurgerNavWrapper>
  ));
}

export default memo(BurgerNav);
