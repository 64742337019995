import styled from 'styled-components';
import { memo, useContext } from 'react';
import { Link } from 'react-router-dom';

import { NavigationContext } from '../../context/NavigationContext';

import colors from '../../styles/colors';
import grids from '../../styles/grids';
import breakpoints from '../../styles/breakpoints';

import { P } from '../ui/Text';
import { SndwrksLogo } from '../ui/SndwrksLogo';

const FooterWrapper = styled.footer`
  height: auto;
  display: grid;
  grid-column-start: overflow-left;
  grid-column-end: overflow-right;
  ${grids.twelveColumnOneRowOverflow}
`;

const Background = styled.div`
  grid-area: 1 / overflow-left / 2 / span 14;
  background: ${colors.primary.green};
`;

const FooterContent = styled.div`
  grid-area: 1 / one / 1 / twelve;
  display: grid;
  grid-template-columns: [left-edge] repeat(12, 1fr) [right-edge];
  grid-template-rows: 6em 5em 4em 4em;
  grid-template-areas: "one-one one-two one-three one-four one-five one-six one-seven one-eight one-nine one-ten one-eleven one-twelve"
                       "two-one two-two two-three two-four two-five two-six two-seven two-eight two-nine two-ten two-eleven two-twelve"
                       "three-one three-two three-three three-four three-five three-six three-seven three-eight three-nine three-ten three-eleven three-twelve"
                       "four-one four-two four-three four-four four-five four-six four-seven four-eight four-nine four-ten four-eleven four-twelve";
  column-gap: 1em;
  row-gap: 0;
  margin-bottom: 2em;

  @media (${breakpoints.xSmall}) {
    grid-template-rows: 6em 15em 22em 4em;
  }
`;

const ContactContent = styled.div`
  grid-area: two-one / two-one / two-one / two-twelve;
  display: grid;
  grid-template-rows: 1fr;
  column-gap: 1em;
  grid-template-columns: auto 1em auto 1em auto;
  grid-template-areas: "email pipe phone pipe2 address";
  align-self: top;
  justify-self: center;

  @media (${breakpoints.xSmall}) {
    grid-template-columns: 1fr;
    grid-template-areas: "email" "pipe" "phone" "pipe2" "address";
    grid-template-rows: 3em 1em 3em 1em 3em;
  }
`;

const PolicyLinkWrapper = styled.div`
  grid-area: three-one / three-one / three-one / three-twelve;
  justify-self: center;
  display: flex;

  @media (${breakpoints.xSmall}) {
    flex-direction: column
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  transition: border .5s ease-in-out;
  border-radius: 1em;
  padding: 0 1em .1em 1em;
  display: flex;
  align-self: center;

  &:hover {
    background-color: ${colors.grey.one70};
  }

  &:active {
    background-color: ${(p) => (p.showNav ? colors.grey.five70 : colors.grey.one70)};
  }
`;

const dtNow = new Date();
const currentYear = ` ${dtNow.getFullYear()} `;

function Footer () {
  const navigationContext = useContext(NavigationContext);
  const { screenWidth } = navigationContext;

  return (
    <FooterWrapper>
      <Background />
      <FooterContent>
        <SndwrksLogo
          $gridArea={screenWidth < 800 ? 'one-five / one-five / one-five / one-eight' : 'one-six / one-six / one-six / one-seven'}
          $margin="5em 0 0 0"
          $justifySelf="center"
          $alignSelf="center"
        />
        <ContactContent>
          <P
            $gridArea="email"
            $textAlign="center"
            $justifySelf="center"
            $color={colors.grey.five}
          >
            info@sndwrks.xyz
          </P>
          <P
            $textAlign="center"
            $justifySelf="center"
            $color={colors.grey.five}
            $margin="0 0 .2em 0"
            $gridArea="pipe"
          >
            |
          </P>
          <P
            $gridArea="phone"
            $textAlign="center"
            $justifySelf="center"
            $color={colors.grey.five}
          >
            970.445.7710
          </P>
          <P
            $textAlign="center"
            $justifySelf="center"
            $color={colors.grey.five}
            $margin="0 0 .2em 0"
            $gridArea="pipe2"
          >
            |
          </P>
          <P
            $gridArea="address"
            $textAlign="center"
            $justifySelf="center"
            $alignSelf="center"
            $color={colors.grey.five}
          >
            517 3rd Ave #1L Brooklyn NY 11215
          </P>
        </ContactContent>
        <PolicyLinkWrapper>
          <StyledLink to="/disclaimer">
            <P
              $textAlign="center"
              $justifySelf="center"
              $color={colors.grey.five}
            >
              Disclaimer
            </P>
          </StyledLink>
          <P
            $textAlign="center"
            $justifySelf="center"
            $color={colors.grey.five}
            $margin="0 0 .2em 0"
          >
            |
          </P>
          <StyledLink to="/terms">
            <P
              $textAlign="center"
              $justifySelf="center"
              $color={colors.grey.five}
            >
              Terms of Use
            </P>
          </StyledLink>
          <P
            $textAlign="center"
            $justifySelf="center"
            $margin="0 0 .2em 0"
            $color={colors.grey.five}
          >
            |
          </P>
          <StyledLink to="/privacy">
            <P
              $textAlign="center"
              $justifySelf="center"
              $color={colors.grey.five}
            >
              Privacy Policy
            </P>
          </StyledLink>
          <P
            $textAlign="center"
            $justifySelf="center"
            $margin="0 0 .2em 0"
            $color={colors.grey.five}
          >
            |
          </P>
          <StyledLink to="/shipping">
            <P
              $textAlign="center"
              $justifySelf="center"
              $color={colors.grey.five}
            >
              Shipping Policy
            </P>
          </StyledLink>
          <P
            $textAlign="center"
            $justifySelf="center"
            $margin="0 0 .2em 0"
            $color={colors.grey.five}
          >
            |
          </P>
          <StyledLink to="/returns">
            <P
              $textAlign="center"
              $justifySelf="center"
              $color={colors.grey.five}
            >
              Return Policy
            </P>
          </StyledLink>
        </PolicyLinkWrapper>
        <P $gridArea="four-one / four-one / four-one / four-twelve" $color={colors.grey.five}>
          Copyright ©
          {currentYear}
          sndwrks LLC. All Rights Reserved
        </P>
      </FooterContent>
    </FooterWrapper>
  );
}

export default memo(Footer);
