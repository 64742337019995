import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';

import { NavigationContext } from '../../context/NavigationContext';

import colors from '../../styles/colors';
import grids from '../../styles/grids';
import breakpoints from '../../styles/breakpoints';

import { P } from '../ui/Text';
import ThreeSmallCards from '../cards/ThreeSmallCards';
import { HazeWatchLogo } from '../ui/HazeWatchLogo';

const HazeWatchWrapper = styled.section`
  height: auto;
  display: grid;
  grid-column-start: overflow-left;
  grid-column-end: overflow-right;
  ${grids.twelveColumnOneRowOverflow}
`;

const Background = styled.div`
  grid-area: 1 / overflow-left / 2 / span 14;
  background: ${colors.grey.one};
`;

const OrangePillWrapper = styled.div`
  grid-area: eleven / eleven / eleven / overflow-right;
  overflow: hidden;

  @media (${breakpoints.small}) {
    grid-area: eleven / nine / eleven / overflow-right;
  }
`;

const OrangePill = styled.div`
  background: ${colors.primary.accentLight};
  height: 90vh;
  border-radius: 40em;
  z-index: 90;
  margin: 55em -10em 0 0;
  max-width: 44em;

  @media (${breakpoints.small}) {
    margin: 40em -10em 0 0;
  }

  @media (${breakpoints.xSmall}) {
    margin: 50em -10em 0 0;
  }
`;

const GreenPillWrapper = styled.div`
  grid-area: overflow-left / overflow-left / overflow-left / two;
  overflow: hidden;
  align-self: end;
`;

const GreenPill = styled.div`
  background: ${colors.primary.main};
  height: 60em;
  border-radius: 50em;
  z-index: 90;
  margin: 0 0 -30em -10em;
  max-width: 50em;
`;

const TransitionToFurnitureWrapper = styled.div`
  grid-area: four-seven / four-seven / four-seven / four-ten;
  grid-template-rows: 6em 2em 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "cta" "v" "image";
  display: grid;
  margin: 10em 0 0 0;
  height: 48em;
  padding: 2em 0 0 0;
  z-index: 101;
  background-color: ${colors.grey.five};
  border-radius: 50em;
  border: .5em solid ${colors.grey.three};

  @media (${breakpoints.small}) {
    grid-area: four-seven / four-seven / four-seven / four-twelve;
    height: 40em;
  }

  @media (${breakpoints.xSmall}) {
    grid-area: four-two / four-two / four-two / four-eleven;
    height: 36em;
  }
`;

const HazeWatchContent = styled.div`
  grid-area: 1 / one / 1 / twelve;
  display: grid;
  grid-template-columns: [left-edge] repeat(12, 1fr) [right-edge];
  grid-template-rows: 22em 24em 1fr 1fr 30em;
  grid-template-areas: "header-one header-two header-three header-four header-five header-six header-seven header-eight header-nine header-ten header-eleven header-twelve"
                        "one-one one-two one-three one-four one-five one-six one-seven one-eight one-nine one-ten one-eleven one-twelve"
                        "two-one two-two two-three two-four two-five two-six two-seven two-eight two-nine two-ten two-eleven two-twelve"
                        "three-one three-two three-three three-four three-five three-six three-seven three-eight three-nine three-ten three-eleven three-twelve"
                        "four-one four-two four-three four-four four-five four-six four-seven four-eight four-nine four-ten four-eleven four-twelve";
  column-gap: 1em;
  row-gap: 0;

  @media (${breakpoints.small}) {
    margin: 14em 0 0 0;
    grid-template-rows: 15em 18em 1fr 1fr 30em;
  }
`;

const FurnitureHeaderImg = styled.img`
  grid-area: image;
  align-self: center;
  justify-self: center;
  border-radius: 50%;
  border: .1em solid ${colors.grey.three};
  margin: 12em 0 0 0;
  height: 18em;
  width: 18em;
  z-index: 110;

  @media (${breakpoints.medium}) {
    height: 16em;
    width: 16em;
    margin: 16em 0 0 0;
  }

  @media (${breakpoints.small}) {
    margin: 8em 0 0 0;
    height: 14em;
    width: 14em;
  }

  @media (${breakpoints.xSmall}) {
    margin: 10em 0 0 0;
    height: 12em;
    width: 12em;
  }
`;

function HazeWatch () {
  const navigationContext = useContext(NavigationContext);
  const { screenWidth, hazeWatchRef } = navigationContext;

  const [hazeWatch$gridAreas, setHazeWatch$gridAreas] = useState({
    hazeWatchLogo: 'header-six / header-eight / header-six / header-twelve',
    hazeWatchP$gridArea: 'one-six / one-eight / one-six / one-twelve',
  });

  const [card$gridAreas, setCard$gridAreas] = useState({
    cardOne: 'two-two / two-two / two-two / two-eleven',
    cardTwo: 'three-two / three-two / three-two / three-eleven',
  });

  useEffect(() => {
    if (screenWidth < breakpoints.smallNumber) {
      setHazeWatch$gridAreas({
        hazeWatchLogo: 'header-two / header-two / header-one / header-eleven',
        hazeWatchP$gridArea: 'one-two / one-two / one-one / one-eleven',
      });
    } else if (screenWidth < breakpoints.medium && screenWidth >= breakpoints.smallNumber) {
      setHazeWatch$gridAreas({
        hazeWatchLogo: 'header-seven / header-seven / header-seven / header-twelve',
        hazeWatchP$gridArea: 'one-seven / one-seven / one-seven / one-twelve',
      });
    } else {
      setHazeWatch$gridAreas({
        hazeWatchLogo: 'header-six / header-eight / header-six / header-twelve',
        hazeWatchP$gridArea: 'one-six / one-eight / one-six / one-twelve',
      });
    }
  }, [screenWidth]);

  useEffect(() => {
    if (screenWidth < breakpoints.xSmallNumber) {
      setCard$gridAreas({
        cardOne: 'two-one / two-one / two-two / two-twelve',
        cardTwo: 'three-one / three-one / three-one / three-twelve',
      });
    } else if (screenWidth < 700) {
      setCard$gridAreas({
        cardOne: 'two-two / two-one / two-two / two-twelve',
        cardTwo: 'three-two / three-one / three-two / three-twelve',
      });
    } else {
      setCard$gridAreas({
        cardOne: 'two-two / two-two / two-two / two-eleven',
        cardTwo: 'three-two / three-two / three-two / three-eleven',
      });
    }
  }, [screenWidth]);

  return (
    <HazeWatchWrapper ref={hazeWatchRef}>
      <Background />
      <OrangePillWrapper>
        <OrangePill />
      </OrangePillWrapper>
      <GreenPillWrapper>
        <GreenPill />
      </GreenPillWrapper>
      <HazeWatchContent>
        <TransitionToFurnitureWrapper>
          <FurnitureHeaderImg
            alt="A rack with cables"
            src="https://sndwrks-website-images.s3.amazonaws.com/rack.jpg"
            height="15em"
          />
        </TransitionToFurnitureWrapper>
        <HazeWatchLogo
          $gridArea={hazeWatch$gridAreas.hazeWatchLogo}
          $margin="10em 0 0 0"
          $justifySelf="left"
          $alignSelf="center"
        />
        <P
          $gridArea={hazeWatch$gridAreas.hazeWatchP$gridArea}
          $maxWidth="18em"
          $color={colors.grey.five}
          $textAlign="left"
          $lineHeight="1.7"
          $justifySelf="left"
          $alignSelf="top"
        >
          Haze Watch is a patent-pending, distributed IoT solution to keep track of your atmospherics. Put a number to the look, and recreate the effect night after night. Haze Watch keeps designers, directors, and operators happy.
          <br />
          <br />
          The system is composed of Onsite Hardware and Cloud Infrastructure. All of this for a monthly rental fee.
        </P>
        <ThreeSmallCards
          $gridArea={card$gridAreas.cardOne}
          title="Hardware Components"
          zIndex="110"
          cardOneCircleText="Sensors"
          cardOneText="Typical package includes two sensors but can include more."
          cardTwoCircleText="Server"
          cardTwoText="A local server is included in every package. Your system will always run even if you don't have internet. The server makes available a local website dashboard."
          cardThreeCircleText="Viewers"
          cardThreeText="Micro computers with an HDMI output for local viewing. Connect one to the local network and view the whole system."
        />
        <ThreeSmallCards
          $gridArea={card$gridAreas.cardTwo}
          title="Cloud Components"
          zIndex="110"
          cardOneCircleText="Metrics"
          cardOneText="We keep track of how your system is performing and notify you if it’s not at its peak."
          cardTwoCircleText="Backups"
          cardTwoText="Haze-watch automatically backs itself up. So you never have to worry about a failure losing critical data."
          cardThreeCircleText="Access"
          cardThreeText="Can’t make it to the show tonight? No problem. Log in from anywhere and monitor performance."
        />
      </HazeWatchContent>
    </HazeWatchWrapper>
  );
}

export default HazeWatch;
