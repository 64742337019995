import { createContext, useRef, useMemo, useEffect, useState } from 'react';
import { throttle } from 'throttle-debounce';
import { useNavigate, useLocation } from 'react-router-dom';

function navigateWrapper (navigateFunction) {
  return (callback) => {
    navigateFunction();
    if (callback) callback();
  };
}

export const NavigationContext = createContext({});

export function NavigationContextProvider (props) {
  const { children } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const [scrollY, setScrollY] = useState(0);
  const [showNav, setShowNav] = useState(false);
  const [navigating, setNavigating] = useState(false);
  const [screenWidth, setScreenWidth] = useState(null);
  const [lastPathname, setLastPathname] = useState(null);
  const [scrolling, setScrolling] = useState(false);
  // const [links, setLinks] = useState();

  const homeRef = useRef();
  const hazeWatchRef = useRef();
  const oscRef = useRef();
  const servicesRef = useRef();

  // console.log('Navigation Context', { pathname, scrolling, scrollY, showNav, navigating, screenWidth, hazeWatchRef, furnitureRef, servicesRef });

  const throttleSetScroll = throttle(10, false, (scrollPos) => {
    setScrollY(scrollPos);
  });

  function navigateToHome () {
    if (homeRef.current) homeRef.current.scrollIntoView({ behavior: 'smooth' });
    setNavigating(true);
  }

  function navigateToHazeWatch () {
    if (hazeWatchRef.current) hazeWatchRef.current.scrollIntoView({ behavior: 'smooth' });
    setNavigating(true);
  }

  function navigateToOsc () {
    if (oscRef.current) oscRef.current.scrollIntoView({ behavior: 'smooth' });
    setNavigating(true);
  }

  function navigateToServices () {
    if (servicesRef.current) servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    setNavigating(true);
  }

  const links = useMemo(() => ({
    hazeWatch: {
      slug: '/haze-watch',
      name: 'Haze Watch',
      key: 'haze_watch',
      onClick: () => navigateWrapper(navigateToHome),
      $gridAreaLarge: '1 / five / 1 / six',
      $gridAreaSmall: '1 / four / 1 / six',
    },
    osc: {
      slug: '/osc',
      name: 'OSC',
      key: 'osc',
      onClick: () => navigateWrapper(navigateToOsc),
      $gridAreaLarge: '1 / seven / 1 / eight',
      $gridAreaSmall: '1 / eight / 1 / nine',
    },
    services: {
      slug: '/services',
      name: 'Services',
      key: 'services',
      onClick: () => navigateWrapper(navigateToServices),
      $gridAreaLarge: '1 / nine / 1 / ten',
      $gridAreaSmall: '1 / ten / 1 / twelve',
    },
  }), []);

  // useEffect(() => {
  //   setLinks({

  //   });
  // }, [links]);

  useEffect(() => {
    if (pathname === '/' || pathname === '/haze-watch' || pathname === '/services' || pathname === '/osc') {
      if (scrollY >= window.innerHeight - 1000) setShowNav(true);
      if (showNav && scrollY < window.innerHeight - 1000) setShowNav(false);
    }
  }, [scrollY, showNav, pathname]);

  useEffect(() => {
    let timeout = null;
    if (navigating) timeout = setTimeout(() => setNavigating(false), 1000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [navigating]);

  useEffect(() => {
    let timeout = null;
    if (scrolling) timeout = setTimeout(() => setScrolling(false), 1000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [scrolling]);

  useEffect(() => {
    if (scrollY === 0 && pathname !== '/' && !navigating) {
      if (pathname === '/haze-watch') navigateToHazeWatch();
      if (pathname === '/osc') navigateToOsc();
      if (pathname === '/services') navigateToServices();
    }
  }, [pathname, scrollY, navigating, lastPathname, scrolling]);

  useEffect(() => {
    if (!navigating && !scrolling && lastPathname) {
      if (
        pathname === '/' && lastPathname !== '/'
      ) {
        navigate('/');
        setLastPathname(pathname);
        if (!scrolling) navigateToHome();
      }
      if (
        pathname === '/haze-watch' && lastPathname !== '/haze-watch'
      ) {
        navigate('/haze-watch');
        setLastPathname(pathname);
        if (!scrolling) navigateToHazeWatch();
      }
      if (
        pathname === '/osc' && lastPathname !== '/osc'
      ) {
        navigate('/osc');
        setLastPathname(pathname);
        if (!scrolling) navigateToOsc();
      }
      if (
        pathname === '/services' && lastPathname !== '/services'
      ) {
        navigate('/services');
        setLastPathname(pathname);
        if (!scrolling) navigateToServices();
      }
    }
    if (scrolling && !navigating) {
      if (
        hazeWatchRef.current
        && scrollY < hazeWatchRef.current.offsetTop
        && pathname !== '/'
      ) {
        navigate('/');
        setLastPathname('/');
      }
      if (
        hazeWatchRef.current
        && oscRef.current
        && scrollY >= hazeWatchRef.current.offsetTop
        && scrollY < oscRef.current.offsetTop
        && pathname !== '/haze-watch'
      ) {
        navigate('/haze-watch');
        setLastPathname('/haze-watch');
      }
      if (
        servicesRef.current
        && oscRef.current
        && scrollY >= oscRef.current.offsetTop
        && scrollY < servicesRef.current.offsetTop
        && pathname !== '/osc'
      ) {
        navigate('/osc');
        setLastPathname('/osc');
      }
      if (
        servicesRef.current
        && scrollY >= servicesRef.current.offsetTop
        && pathname !== '/services'
      ) {
        navigate('/services');
        setLastPathname('/services');
      }
    }
  }, [scrollY, navigate, navigating, pathname, scrolling, lastPathname]);

  useEffect(() => {
    if (pathname === '/disclaimer') setShowNav(true);
    if (pathname === '/privacy') setShowNav(true);
    if (pathname === '/terms') setShowNav(true);
    if (pathname === '/returns') setShowNav(true);
    if (pathname === '/shipping') setShowNav(true);
  }, [pathname]);

  useEffect(() => {
    function handleResize () {
      setScreenWidth(window.innerWidth);
    }

    setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const navigationContext = useMemo(() => ({
    showNav,
    throttleSetScroll,
    setScrolling,
    homeRef,
    hazeWatchRef,
    oscRef,
    servicesRef,
    navigateToHome,
    navigateToHazeWatch,
    navigateToOsc,
    navigateToServices,
    screenWidth,
    links,
  }), [showNav, throttleSetScroll, screenWidth, links]);

  return <NavigationContext.Provider value={navigationContext}>{children}</NavigationContext.Provider>;
}
