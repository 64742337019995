import { useContext } from 'react';
import styled from 'styled-components';

import { NavigationContext } from '../../context/NavigationContext';

import colors from '../../styles/colors';
import grids from '../../styles/grids';
import breakpoints from '../../styles/breakpoints';

import { JumbotronText, H4 } from '../ui/Text';
import { SndwrksLogo } from '../ui/SndwrksLogo';
import { V } from '../ui/V';

const HomeWrapper = styled.section`
  grid-column-start: overflow-left;
  grid-column-end: overflow-right;
  height: 100vh;
  display: grid;
  ${grids.twelveColumnOneRowOverflow}
  overflow: visible;
`;

const BackgroundLeft = styled.div`
  background: ${colors.primary.main};
  grid-area: overflow-left / overflow-left / 1 / span 5;
  height: 90vh;
  border-radius: 40em;
  z-index: 100;
  margin-top: 55vh;
  display: grid;
  margin-left: 10rem;

  @media (${breakpoints.medium}) {
    margin-left: 0;
  } 

  @media (${breakpoints.small}) {
    grid-area: one / one / one / twelve;
    border-radius: 10em;
    height: 30em;
    margin-top: 70vh;
  }

  @media (${breakpoints.small}) {
    border-radius: 8em;
  }
`;

const BackgroundRight = styled.div`
  grid-area: overflow-left / overflow-left / 1 / span 14;
  background-image: url("https://sndwrks-website-images.s3.amazonaws.com/sndwrks-banner-2.jpg");
  background-position: 0 -400px;
  background-size: 120vw;
  background-color: ${colors.grey.five};
  // https://css-tricks.com/almanac/properties/b/background-blend-mode/
  background-blend-mode: overlay;

  @media (max-width: 1600px) {
    background-position: 0 -200px;
  }

  @media (max-width: 1080px) {
    background-position: 0 -100px;
  }

  @media (max-width: 900px) {
    background-position: 0 -100px;
    background-size: 200vh;
    height: 100%;
  }
`;
/*
const HomeLeft = styled.div`
  grid-area: overflow-left / overflow-left / 1 / span 4;
  display: contents;
  overflow: visible;
`;
*/

const JumboWrapper = styled.div`
  grid-area: 1 / four / 1 / twelve;
  align-self: center;
  display: grid;
  grid-template-rows: 10em;
  grid-template-columns: 1fr;
  grid-template-areas: "heading";
  margin: 0 0 70vh 0;

  @media (${breakpoints.small}) {
    grid-area: 1 / one / 1 / twelve;
    margin: 0 0 50vh 0;
  }
`;

const CTAWrapper = styled.div`
  grid-area: 1 / three / 1 / six;
  grid-template-rows: 6em 2em 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "cta" "v" "image";
  display: grid;
  margin: 80vh 0 0 0;
  height: 48em;
  padding: 2em 0 0 0;
  z-index: 101;
  background-color: ${colors.grey.five};
  border-radius: 50em;
  border: .5em solid ${colors.grey.three};

  @media (max-width: 1400px) {
    grid-template-rows: 4em 2em 1fr;
    padding: 1em 0 0 0;
  }

  @media (${breakpoints.medium}) {
    height: 40em;
    grid-area: 1 / three / 1 / five;
    padding: 0;
  }

  @media (${breakpoints.small}) {
    grid-area: one / five / one / eight;
    height: 24em;
    border-radius: 5em;
    margin: 85vh 0 0 0;
    grid-template-rows: 4em 1em 1fr;
    height: 20em;
    border: .4em solid ${colors.grey.three};
  }

  @media (${breakpoints.xSmall}) {
    grid-area: one / three / one / ten;
    height: 24em;
    border-radius: 5em;
    margin: 84vh 0 0 0;
    grid-template-rows: 4em 1em 1fr;
    height: 20em;
    border: .4em solid ${colors.grey.three};
  }
`;

const HazeWatchHeaderImg = styled.img`
  grid-area: image;
  align-self: center;
  justify-self: center;
  border-radius: 50%;
  border: .1em solid ${colors.grey.three};
  margin: 12em 0 0 0;
  height: 18em;
  width: 18em;
  z-index: 110;

  @media (${breakpoints.medium}) {
    height: 16em;
    width: 16em;
    margin: 13em 0 0 0;
  }

  @media (${breakpoints.small}) {
    height: 10em;
    width: 10em;
    margin: 0 0 0 0;
  }
`;

function Home () {
  const navigationContext = useContext(NavigationContext);
  const { screenWidth, homeRef } = navigationContext;

  function sndwrksMargin () {
    if (screenWidth < breakpoints.xSmallNumber) return '2em 0 0 0';
    if (screenWidth < breakpoints.smallNumber) return '4em 0 0 0';
    if (screenWidth < breakpoints.mediumNumber) return '6em 0 0 0';
    return '14em 0 0 0';
  }

  return (
    <HomeWrapper ref={homeRef}>
      <BackgroundRight />
      <BackgroundLeft>
        <SndwrksLogo
          $alignSelf="top"
          $justifySelf="center"
          $margin={() => sndwrksMargin()}
          $zIndex="101"
          $height="1em"
          $width={screenWidth < breakpoints.mediumNumber ? '10em' : '15em'}
          $gridArea={screenWidth < breakpoints.mediumNumber ? '1 / 1 / 1 / 1' : '1 / 1 / 1 / 1'}
        />
      </BackgroundLeft>
      <JumboWrapper>
        <JumbotronText
          $gridArea="heading"
          $justifySelf={screenWidth < breakpoints.smallNumber ? 'center' : 'left'}
          $alignSelf="top"
          $textAlign="center"
          width="100"
          $color={colors.grey.one}
          $margin="0 0 0 0"
        >
          Internet of Things for
          {screenWidth < breakpoints.xSmallNumber ? <br /> : ' '}
          Entertainment.
        </JumbotronText>
      </JumboWrapper>
      <CTAWrapper>
        <H4
          $gridArea="cta"
          $justifySelf="center"
          $alignSelf="center"
          $textAlign="center"
          $margin="0 0 .5em 0"
          $color={colors.grey.one}
        >
          See for yourself.
        </H4>
        <V
          $gridArea="v"
          $justifySelf="center"
          $alignSelf="center"
        />
        <HazeWatchHeaderImg
          alt="Live Performance with Haze"
          src="https://sndwrks-website-images.s3.amazonaws.com/haze-watch-photo.jpg"
        />
      </CTAWrapper>
    </HomeWrapper>
  );
}

export default Home;
