import { useContext, useRef } from 'react';
import styled from 'styled-components';

import { NavigationContext } from '../../context/NavigationContext';

import grids from '../../styles/grids';

import Home from '../home/Home';
import HazeWatch from '../haze-watch/HazeWatch';
// import Furniture from '../furniture/Furniture';
import Osc from '../osc/Osc';
import Services from '../services/Services';
import TheEnd from '../footer/TheEnd';
import Footer from '../footer/Footer';

const ScrollContainerWrapper = styled.div`
  display: grid;
  height: 100%;
  grid-area: nav-overflow-left / nav-overflow-left / overflow-right / nav-overflow-right;
  ${grids.twelveColumnOneRowOverflow}
  overflow-y: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

function ScrollContainer () {
  const navigationContext = useContext(NavigationContext);
  const { throttleSetScroll, setScrolling } = navigationContext;

  const scrollContainerRef = useRef();

  function onScroll () {
    if (scrollContainerRef.current) throttleSetScroll(scrollContainerRef.current.scrollTop);
    setScrolling(true);
  }
  return (
    <ScrollContainerWrapper onScroll={onScroll} ref={scrollContainerRef}>
      <Home />
      <HazeWatch />
      <Osc />
      <Services />
      <TheEnd />
      <Footer />
    </ScrollContainerWrapper>
  );
}

export default ScrollContainer;
