import { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { NavigationContext } from '../../context/NavigationContext';

import colors from '../../styles/colors';
import breakpoints from '../../styles/breakpoints';

import { H3 } from '../ui/Text';

const StyledLink = styled(Link)`
  grid-area: ${({ $gridArea }) => $gridArea || '1 / 1 / 1 / 1'};
  display: grid;
  text-decoration: none;
  transition: border .5s ease-in-out;
  border-top: .3em solid rgba(0, 0, 0, 0);
  border-bottom: .3em solid rgba(0, 0, 0, 0);
  z-index: 1;

  &:hover {
    border-top: .3em solid rgba(0, 0, 0, 0);
    border-bottom: .3em solid ${({ $showNav }) => ($showNav ? colors.grey.five70 : colors.grey.one70)};
  }

  &:active {
    background-color: ${({ $showNav }) => ($showNav ? colors.grey.five70 : colors.grey.one70)};
  }
`;

function ButtonNav () {
  const navigationContext = useContext(NavigationContext);
  const { showNav, screenWidth, links } = navigationContext;

  return (
    <>
      {
        Object.values(links).map((link) => (
          <StyledLink
            to={link.slug}
            key={link.slug}
            $gridArea={screenWidth < breakpoints.smallNumber
              ? link.$gridAreaSmall
              : link.$gridAreaLarge}
            onClick={link.onClick}
            $showNav={showNav}
          >
            <H3
              $$lineHeight="0"
              $margin="-.15em 0 0 0"
              $color={showNav ? colors.grey.five : colors.grey.three}
            >
              {link.name}
            </H3>
          </StyledLink>
        ))
      }
    </>
  );
}

export default ButtonNav;
