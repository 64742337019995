const colors = {
  primary: {
    main: 'rgba(194, 242, 128, 1)',
    accent: 'rgba(244, 119, 33, 1)',
    accentLight: 'rgba(239, 145, 81, 1)',
  },
  grey: {
    one: 'rgba(234, 231, 226, 1)',
    one70: 'rgba(234, 231, 226, .7)',
    two: 'rgba(187, 188, 192, 1)',
    three: 'rgba(178, 180, 184, 1)',
    four: 'rgba(144, 138, 135, 1)',
    five: 'rgba(14, 22, 25, 1)',
    five70: 'rgba(14, 22, 25, .7)',
  },
};

export default colors;
