import { useEffect } from 'react';

function useTermly () {
  useEffect(() => {
    const js = document.createElement('script');
    js.id = 'termly-jssdk';
    js.src = 'https://app.termly.io/embed-policy.min.js';
    document.body.appendChild(js);
  }, []);
}

export default useTermly;
