import styled from 'styled-components';

import { ReactComponent as Logo } from '../../svg/sndwrks-text-only.svg';

const LogoWrapper = styled.div`
  grid-area: ${(props) => props.$gridArea || '1 / 1 / 1 / 1'};
  justify-self: ${(props) => props.$justifySelf || 'center'};
  align-self: ${(props) => props.$alignSelf || 'center'};
  margin: ${({ $margin }) => $margin || '0 0 0 0'};
  opacity: ${(props) => props.$opacity || 1};
  transition: opacity .5s ease-in-out;
  height: ${(props) => props.$height || '100%'};
  width: ${(props) => props.$width || '100%'};
  z-index: ${(props) => props.$zIndex};
`;

// eslint-disable-next-line import/prefer-default-export
export function SndwrksLogo (props) {
  const { $gridArea, $justifySelf, $alignSelf, $margin, $opacity, $zIndex, $width, $height } = props;

  return (
    <LogoWrapper
      $gridArea={$gridArea}
      $justifySelf={$justifySelf}
      $alignSelf={$alignSelf}
      $margin={$margin}
      $opacity={$opacity}
      $zIndex={$zIndex}
      $height={$height}
      $width={$width}
    >
      <Logo />
    </LogoWrapper>
  );
}
