import PolicyContainer from './PolicyContainer';
import useTermly from './useTermly';

function Terms () {
  useTermly();
  return (
    <PolicyContainer>
      <div name="termly-embed" data-id="04fc2c9a-1449-486b-93e9-10d4bb880f3a" data-type="iframe" />
    </PolicyContainer>
  );
}

export default Terms;
