import { useState, memo } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';
import breakpoints from '../../styles/breakpoints';
import LoadingSpinnerSmall from '../ui/LoadingSpinnerSmall';

const API_URL = process.env.REACT_APP_API_URL;

const FormWrapper = styled.div`
  grid-area: ${(props) => props.$gridArea || '1 / 1 / 1 / 1'};
  display: grid;
  grid-template-rows: 3.5em 3.5em 10.5em;
  grid-template-columns: 1fr 12em;
  grid-template-areas: "email email"
                       "name button"
                       "question question";
  row-gap: 3em;
  column-gap: 3em;
  margin: ${({ $margin }) => $margin || '0 0 0 0'};
  z-index: 110;

  @media (${breakpoints.xSmall}) {
    grid-template-rows: 3.5em 3.5em 10.5em 3.5em;
    grid-template-columns: 1fr 12em;
    grid-template-areas: "email email"
                        "name name"
                        "question question"
                        "blank button";
  }
`;

const FormInput = styled.input`
  grid-area: ${(props) => props.$gridArea || '1 / 1 / 1 / 1'};
  border: .2rem solid ${colors.primary.main};
  background-color: ${colors.grey.five};
  border-radius: 1rem;
  padding: 0 1em .2em 1em;
  color: ${colors.grey.one};
  font-size: 1.6em;
  font-weight: ${fonts.condensed.medium.weight};
  font-family: ${fonts.condensed.fontFamily};
  text-transform: uppercase;

  &:hover {
    border-color: ${colors.primary.accentLight};
  }

  &:focus {
    outline: none;
    border-color: ${colors.grey.one};
  }

  &::placeholder {
    color: ${colors.grey.two};
  }
`;

const FormButton = styled.button`
  grid-area: ${(props) => props.$gridArea || '1 / 1 / 1 / 1'};
  border: .2rem solid ${colors.primary.main};
  background-color: ${colors.grey.five};
  border-radius: 1rem;
  padding: 0 1em .2em 1em;
  color: ${colors.primary.main};
  font-size: 1.6em;
  font-weight: ${fonts.condensed.medium.weight};
  font-family: ${fonts.condensed.fontFamily};
  text-transform: uppercase;

  &:hover {
    border-color: ${colors.primary.accentLight};
    color: ${colors.primary.accentLight};
  }

  &:active {
    outline: none;
    border-color: ${colors.grey.one};
    color: ${colors.grey.five};
    background-color: ${colors.grey.one};
  }

  &:focus {
    outline: none;
    border-color: ${colors.grey.one};
  }
`;

const FormTextArea = styled.textarea`
  grid-area: ${(props) => props.$gridArea || '1 / 1 / 1 / 1'};
  border: .2rem solid ${colors.primary.main};
  border-radius: 1rem;
  background-color: ${colors.grey.five};
  padding: .2em 1em .2em 1em;
  color: ${colors.grey.one};
  font-size: 1.6em;
  font-weight: ${fonts.condensed.medium.weight};
  font-family: ${fonts.condensed.fontFamily};
  text-transform: uppercase;

  &:hover {
    border-color: ${colors.primary.accentLight};
  }

  &:focus {
    outline: none;
    border-color: ${colors.grey.one};
  }

  &::placeholder {
    color: ${colors.grey.two};
  }
`;

function EmailForm ({ $gridArea, textAreaPrompt, margin }) {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);

  function handleChange (e) {
    e.preventDefault();

    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  }

  async function handleClick (e) {
    try {
      e.preventDefault();
      setLoading(true);
      console.log('Button Clicked!', formData);
      const { name, email, message } = formData;

      const headers = { 'Access-Control-Allow-Origin': 'https://www.sndwrks.xyz', 'Content-Type': 'application/json' };

      const result = await axios.post(API_URL, { name, email, content: message }, headers);
      if (result.status === 200) setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <FormWrapper $gridArea={$gridArea} $margin={margin}>
      <FormInput
        $gridArea="email"
        placeholder="Email"
        name="email"
        onChange={handleChange}
        value={formData.email}
      />
      <FormInput
        $gridArea="name"
        placeholder="Preferred Name"
        name="name"
        onChange={handleChange}
        value={formData.name}
      />
      <FormTextArea
        $gridArea="question"
        placeholder={textAreaPrompt}
        name="message"
        onChange={handleChange}
        value={formData.message}
      />
      {loading
        ? <LoadingSpinnerSmall />
        : (
          <FormButton $gridArea="button" onClick={handleClick}>
            Send!
          </FormButton>
        )}
      ()
    </FormWrapper>
  );
}

export default memo(EmailForm);
