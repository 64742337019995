import styled from 'styled-components';

import colors from '../../styles/colors';
import fonts from '../../styles/fonts';
import breakpoints from '../../styles/breakpoints';

function color ({ $color }) { return $color || colors.grey.four; }
function hoverColor ({ $hoverColor }) { return $hoverColor || colors.grey.one; }
function gridArea ({ $gridArea }) { return $gridArea || ''; }
function justifySelf ({ $justifySelf }) { return $justifySelf || 'center'; }
function alignSelf ({ $alignSelf }) { return $alignSelf || 'center'; }
function textAlign ({ $textAlign }) { return $textAlign || 'center'; }
function margin ({ $margin }) { return $margin || '0 0 0 0'; }
function lineHeight ({ $lineHeight }) { return $lineHeight || ''; }
function maxWidth ({ $maxWidth }) { return $maxWidth; }

export const JumbotronText = styled.h1`
  font-weight: ${fonts.condensed.medium.weight};
  font-size: 5.5em;
  color: ${color};
  grid-area: ${gridArea};
  justify-self: ${justifySelf};
  align-self: ${alignSelf};
  text-align: ${textAlign};
  text-transform: uppercase;
  line-height: ${lineHeight};
  margin: ${margin};

  @media (${breakpoints.medium}) {
    font-size: 4.5em;
  }

  @media (${breakpoints.xSmall}) {
    font-size: 4em;
  }
`;

export const H2 = styled.h2`
  font-weight: ${fonts.condensed.medium.weight};
  font-size: 2.5em;
  color: ${color};
  grid-area: ${gridArea};
  justify-self: ${justifySelf};
  align-self: ${alignSelf};
  text-align: ${textAlign};
  line-height: ${lineHeight};
  margin: ${margin};
  transition: color .5s ease-in-out;

  @media (${breakpoints.xSmall}) {
    font-size: 2.2em;
  }
`;

export const H3 = styled.h3`
  font-weight: ${fonts.condensed.medium.weight};
  font-size: 2.5em;
  color: ${color};
  grid-area: ${gridArea};
  justify-self: ${justifySelf};
  align-self: ${alignSelf};
  text-align: ${textAlign};
  text-transform: uppercase;
  line-height: ${lineHeight};
  margin: ${margin};
  transition: color .5s ease-in-out;

  @media (max-width: 800px) {
    font-size: 4em;
    user-select: none;
  }

  
`;

export const H3Hover = styled.h3`
  font-weight: ${fonts.condensed.medium.weight};
  font-size: 2.5em;
  color: ${color};
  grid-area: ${gridArea};
  justify-self: ${justifySelf};
  align-self: ${alignSelf};
  text-align: ${textAlign};
  text-transform: uppercase;
  line-height: ${lineHeight};
  margin: ${margin};
  transition: color .5s ease-in-out;

  &:hover {
    color: ${hoverColor};
  }

  &:active {
    color: ${colors.grey.one};
  }
`;

export const H4 = styled.h4`
  font-weight: ${fonts.condensed.medium.weight};
  font-size: 2em;
  color: ${color};
  grid-area: ${gridArea};
  justify-self: ${justifySelf};
  align-self: ${alignSelf};
  text-align: ${textAlign};
  margin: ${margin};
  line-height: ${lineHeight};

  @media (${breakpoints.medium}) {
    font-size: 1.8em;
  }
`;

export const P = styled.p`
  font-weight: ${fonts.condensed.medium.weight};
  font-size: 1.4em;
  letter-spacing: .2px;
  color: ${color};
  grid-area: ${gridArea};
  justify-self: ${justifySelf};
  align-self: ${alignSelf};
  text-align: ${textAlign};
  margin: ${margin};
  line-height: ${lineHeight};
  max-width: ${maxWidth};
`;
